<template>
  <div class="body">
    <div class="topLine"></div>
    <div class="tab">
      <div class="tableft">
        <div>{{ aboutname }}</div>
        <h2 v-if="aboutname == '农业资讯'">Agricultural news</h2>
      </div>
      <div class="ulBox">
        <ul>
          <li
            :class="indexs === idx ? 'active' : ''"
            v-for="(item, idx) in ulBoxList"
            @click="selectList(item, idx)"
            :key="item.id"
          >
            {{ item.columnName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="bottomLine"></div>
  </div>
</template>

<script>
import { getQuotes } from "../../../api/agricuinfor";
import { getdetail } from "../../../api/home1";
import { mapGetters } from "vuex";
export default {
  name: "body",
  data() {
    return {
      ulBoxList: [],
      height: -1,
      indexs: 0,
      aboutname: "",
      columid: "",
      paths: ""
    };
  },
  computed: {
    ...mapGetters({
      columnData: "getColumnData"
    })
  },
  methods: {
    selectList(item, idx) {
      this.indexs = idx;
      if (item.columnName == "猪业资讯") {
        this.$router.push({
          path: "/piginfothree",
          query: {
            columid: this.columid,
            columnNameId: item.id
          }
        });
      } else {
        this.$router.push({
          path: "/agricuunforThree",
          query: {
            columid: this.columid,
            columnNameId: item.id
          }
        });
      }
      // this.slideScroll(idx)
    },

    getScrollTop() {
      var scrollTop = 0,
        bodyScrollTop = 0,
        documentScrollTop = 0;
      if (document.body) {
        bodyScrollTop = document.body.scrollTop;
      }
      if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop;
      }
      scrollTop =
        bodyScrollTop - documentScrollTop > 0
          ? bodyScrollTop
          : documentScrollTop;
      return scrollTop;
    },

    getScrollHeight() {
      var scrollHeight = 0,
        bodyScrollHeight = 0,
        documentScrollHeight = 0;
      if (document.body) {
        bodyScrollHeight = document.body.scrollHeight;
      }
      if (document.documentElement) {
        documentScrollHeight = document.documentElement.scrollHeight;
      }
      scrollHeight =
        bodyScrollHeight - documentScrollHeight > 0
          ? bodyScrollHeight
          : documentScrollHeight;
      return scrollHeight;
    },

    getWindowHeight() {
      var windowHeight = 0;
      if (document.compatMode == "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    },

    slideScroll(idx) {
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      let height =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //最后一个二级栏目点击后触发的事件
      if (idx == this.ulBoxList.length - 1) {
        let settime = setInterval(function() {
          height += 4;
          var scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;
          if (height == scrollTop) {
            clearInterval(settime);
            return;
          }
          if (scrollTop >= 1020) {
            clearInterval(settime);
          } else {
            window.scrollTo(0, height);
          }
        }, 0.01);
        return;
      }
      //最后一个二级栏目以外的栏目点击后触发的事件
      let settime = setInterval(function() {
        height += 4;
        var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop + windowHeight >= scrollHeight - 2) {
          clearInterval(settime);
          return;
        }
        if (scrollTop >= 272 * (idx + 1) + idx * 35) {
          clearInterval(settime);
        } else {
          window.scrollTo(0, height);
        }
      }, 0.01);
    },

    getUlBoxList() {
      getdetail({
        id: this.$route.query.columnNameId
      }).then(res => {
        this.aboutname = res.data.columnName;
        this.paths = res.data.parentAccessPath;
      });
      getQuotes({ parentId: this.columnData.information.id }).then(item => {
        this.ulBoxList = item.data;
      });
    }
  },

  mounted() {
    this.aboutname = this.$route.query.aboutname;
    this.columid = this.$route.query.columid;
    this.paths = this.$route.query.paths;
    this.getUlBoxList();
  }
};
</script>

<style scoped lang="scss">
.topLine {
  position: relative;
  border-top: 5px solid #51d5c3;
  height: 2px;
  width: 1350px;
  left: -2%;
}
.bottomLine {
  position: relative;
  border-top: 2px solid #51d5c3;
  height: 2px;
  width: 1350px;
  left: -2%;
  top: -26px;
}
.tab {
  display: flex;
  margin-bottom: 30px;
  width: 1300px;
  height: 50px;
  .tableft {
    width: 242px;
    margin: 5px 0 0 10px;
    font-weight: bolder;
    font-size: 16px;
  }
  .ulBox {
    margin: 0 auto;
  }
  ul {
    display: flex;
    justify-content: center;
    li {
      font-size: 18px;
      line-height: 50px;
      margin-right: 70px;
      font-weight: bolder;
      cursor: pointer;
    }
    .active {
      border-bottom: 4px solid #51d5c3;
    }
  }
}
</style>
