<template>
<div>
  <div class="container">
      <div>
        <div class="tab">
            <Tab></Tab>
        </div>
        <div class="flex">
            <div class="left">
                <ContentLeft></ContentLeft>
            </div>
            <div class="right">
                <Agrright></Agrright>
            </div>
        </div>
      <Banner></Banner>
      </div>
    </div>
     <Foot></Foot>
  </div>
</template>

<script>
import Agrright from './components/agrright'
import ContentLeft from './components/contentLeft'
import Tab from './components/tab'
import Banner from '../homeNew/components/banner'
import Foot from '../components/footer'

export default {
    components:{
        ContentLeft,
        Tab,
        Banner,
        Agrright,
        Foot
    },
    data () {
        return {
        }
    },
    methods: {
    },
    mounted () {
    }
}
</script>

<style scoped>
    .left{
        width: 58%;
    }
    .right{
        width: 40%;
        height: 100%;
        max-width: 520px;
    }
    .container{
        margin:0 auto;
        padding: 0;
        width: 90%;
        max-width: 1300px;
    }
    .flex{
        justify-content: space-between;
    }
</style>