<template>
  <div class="contentLeft">
    <div class="internal" v-for="(item, index) in allData" :key="index">
      <head>
        <div class="head-left" v-if="allId.length">
          {{ item.title }}
        </div>
        <div class="head-right" @click="son(index)">
          <h2>更多>></h2>
        </div>
      </head>
      <div
        class="title"
        @click="son1(item.list[0], index)"
        v-if="item.list.length"
      >
        要闻聚焦 | {{ item.list[0].title }}
      </div>
      <ul>
        <li
          v-for="(ite, idx) in item.list"
          :key="ite.id"
          v-show="item.isTop != 0 && idx != 0"
          @click="son1(ite, index)"
        >
          <div class="liContent">{{ item.length == 0 ? "" : ite.title }}</div>
          <span class="date">{{ ite.releaseTime | escapeHTML }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getWebTitle } from "../../../api/home1";
import { getInforId, getContentList } from "../../../api/home1";
export default {
  name: "contentLeft",
  data() {
    return {
      allId: [],
      internalList: [],
      externalList: [],
      newList: [],
      aboutname: "",
      columid: "",
      paths: "",
      allData: []
    };
  },
  computed: {
    ...mapGetters({
      columnData: "getColumnData"
    })
  },
  methods: {
    // getdeta(){
    //   getdetail({id:}).then((res) => {

    //   })
    // },
    //获取网站名称
    getWebTitle2() {
      getWebTitle().then(res => {
        if (!res.data.records[0].status) {
          document.title = res.data.records[0].websiteName;
        } else {
          document.title = "";
        }
      });
    },
    son(id) {
      if (this.allId[id].columnName == "猪业资讯") {
        this.$router.push({
          path: "/piginfothree",
          query: {
            // columid: this.columid,
            columnNameId: this.allId[id].id
          }
        });
      } else {
        this.$router.push({
          path: "/agricuunforThree",
          query: {
            // columid: this.columid,
            columnNameId: this.allId[id].id
          }
        });
      }
    },
    //获取二级栏目列表
    getAllId() {
      getInforId({ parentId: this.columnData.information.id }).then(result => {
        this.allId = result.data;
        this.getItemList();
      });
    },
    getItemList() {
      for (let index = 0; index < this.allId.length - 1; index++) {
        let data = {};
        data.list = [];
        data.title = this.allId[index].columnName;
        this.allData.push(data);
      }
      for (let index = 0; index < this.allId.length - 1; index++) {
        getContentList({ columnId: this.allId[index].id, size: 7 }).then(
          res => {
            this.allData[index].list = res.data.records;
          }
        );
      }
    },
    son1(item, index) {
      let columnNameId = this.allId[index].id;
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          titleId: item.id
          // columnNameId,
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    }
  },
  mounted() {
    this.columid = this.$route.query.columid;
    // this.paths=this.$route.query.paths
    // this.aboutname = this.$route.query.aboutname
    this.getAllId();
    this.getWebTitle2();
  },
  filters: {
    escapeHTML(untrusted) {
      if (untrusted != undefined) {
        return untrusted.substring(0, 16);
      }
    }
  }
};
</script>

<style scoped lang="scss">
li {
  cursor: pointer;
}
.contentLeft {
  max-width: 770px;
  padding-left: 30px;
  head {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    .head-left {
      color: #fff;
      font-size: 18px;
      text-align: center;
      width: 100px;
      background: #51d5c3;
      line-height: 30px;
      text-align: center;
      position: relative;
      border-bottom: 2px solid #08a085;
    }
    .head-left::after {
      content: "";
      width: 1px;
      height: 5px;
      display: inline-block;
      border: 5px solid transparent;
      border-top: 5px solid #08a085;
      font-size: 100% 100%;
      position: absolute;
      left: 40px;
      bottom: -15px;
    }
    .head-right {
      width: 700px;
      color: #7e7e7e;
      border-bottom: 2px solid #c2c2c2;
      padding-top: 4px;
      margin-right: 10px;
      cursor: pointer;
      h2 {
        float: right;
        margin-right: 20px;
      }
      .more {
        color: #646464;
        font-size: 13px;
        float: right;
        padding-right: 14px;
        background-image: url("../../../assets/img/icon_more.png");
        background-size: auto 10px;
        background-repeat: no-repeat;
        background-position: center right;
        display: inline-block;
        cursor: pointer;
        margin-top: 5px;
      }
    }
  }
  .title {
    font-size: 16px;
    font-weight: bolder;
    margin: 16px 0;
    cursor: pointer !important;
  }
  ul {
    height: 210px;
    padding-left: 20px;
    overflow: auto;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 10px;
    li {
      font-size: 14px;
      list-style-type: disc;
      line-height: 30px;
      // display: flex;
      // justify-content: space-between;
      .liContent {
        // display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 430px;
      }
      .date {
        float: right;
        margin-right: 50px;
        position: relative;
        top: -30px;
      }
      p {
        width: 430px;
      }
    }
    li:hover {
      color: #51d5c3;
    }
  }
}
.head-right h2:hover,
.title:hover {
  color: #51d5c3;
}
.internal-img {
  width: 100%;
  margin-bottom: 10px;
}
</style>
