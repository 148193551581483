<template>
  <div class="container">
    <div class="bannerbg">
      <div class="banner">
        <div class="item" @mouseover="mouseOver" @mouseleave="mouseLeave">
          <img :src="Img[currentIndex]" @click="bannerson(currentIndex)" />
        </div>
        <div class="page" v-if="this.Img.length">
          <div class="font">{{ label[currentIndex] | ellipsis }}</div>
          <ul style="" class="ul">
            <li
              v-for="(item, index) in Img"
              :key="index"
              @click="gotoPage(index)"
              :class="{ current: currentIndex == index }"
              class="li"
            ></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="New">
      <h1>今日热闻<span>HOT</span></h1>
      <ul>
        <li
          style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;"
          v-for="(item, idx) in relnew"
          :key="idx"
          @click="jump(item)"
        >
          <span>{{ idx + 1 }}</span
          >{{ item.title }}
        </li>
      </ul>
    </div>
    <div class="Zhuanti">
      <div class="top flex">
        <h1>
          {{ zti.columnName }}
        </h1>
        <p @click="son()">更多>></p>
      </div>
      <div class="list">
        <ul>
          <li
            class="flex"
            v-for="(item, idx) in relhot.slice(0, 4)"
            :key="idx"
            @click="jump(item)"
          >
            <img :src="item.icon" alt="" />
            <div class="position">
              <h3>{{ item.title }}</h3>
              <p>{{ item.releaseTime.slice(0, 16) }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNews,
  getQuotes,
  getQuotesList,
  getdetail
} from "@/api/agricuinfor";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      relnew: "",
      relhot: "",
      Img: [],
      label: [],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      aboutname: "",
      columid: "",
      paths: "",
      zti: "",
      bannerjump: ""
    };
  },
  methods: {
    getcolumid() {
      getQuotes({
        parentId: this.columid
      }).then(res => {
        getQuotesList({
          columnId: res.data[res.data.length - 1].id
        }).then(res => {
          this.relhot = res.data.records;
        });
      });
    },
    getcolum() {
      getQuotes({
        parentId: this.columid
      }).then(res => {
        this.zti = res.data[res.data.length - 1];
      });
    },
    getList() {
      getQuotesList({
        columnId: this.columnId
      }).then(res => {
        this.bannerjump = res.data.records;
        for (let i = 0; i < 3; i++) {
          this.Img.push(res.data.records[i].icon);
          this.label.push(res.data.records[i].title);
        }
      });
    },
    getnew() {
      getNews({
        type: 2,
        columnId: this.columid
      }).then(res => {
        this.relnew = res.data.records;
      });
    },
    gotoPage(index) {
      this.currentIndex = index;
    },
    runInv() {
      this.timer = setInterval(() => {
        this.gotoPage(this.nextIndex);
      }, 3000);
    },
    mouseOver() {
      clearInterval(this.timer);
    },
    mouseLeave() {
      this.runInv();
    },
    son() {
      this.$router.push({
        path: "/agricuunforThree",
        query: {
          columnNameId: this.zti.id
        }
      });
    },
    jump(item) {
      getdetail({
        id: item.columnId
      }).then(res => {
        let routerJump = this.$router.resolve({
          path: "/domesticDetails",
          query: {
            // columid: this.columid,
            // columnNameId:res.data.id,
            titleId: item.id
          }
        });
        window.open(routerJump.href, "_blank");
      });
    },
    bannerson(idx) {
      getdetail({
        id: this.bannerjump[idx].columnId
      }).then(res => {
        let routerJump = this.$router.resolve({
          path: "/domesticDetails",
          query: {
            // columid: this.columid,
            // columnNameId:res.data.id,
            titleId: this.bannerjump[idx].id
          }
        });
        window.open(routerJump.href, "_blank");
      });
    }
  },
  mounted() {
    this.aboutname = this.$route.query.aboutname;
    this.columid = this.$route.query.columid;
    this.paths = this.$route.query.paths;
    this.getcolumid();
    this.getnew();
    this.runInv();
    this.getcolum();
    this.getList();
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 30) {
        return value.slice(0, 30) + "...";
      }
      return value;
    }
  },
  computed: {
    prevIndex() {
      if (this.currentIndex == 0) {
        return this.Img.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.currentIndex == this.Img.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
    ...mapGetters({
      columnData: "getColumnData"
    })
  },
  watch: {
    columnData(val) {
      if (JSON.stringify(val) !== "{}") {
        const cId = this.columnData;
      }
    },
    immediate: true
  }
};
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0;
}
.tab {
  width: 100%;
  border: 1px solid #000;
  margin-bottom: 40px;
}
.ul {
  height: 30px;
  padding-right: 20px;
  justify-content: space-between;
}
.li {
  display: none;
}
.banner {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.banner img {
  width: 100%;
  height: 300px;
  display: block;
}
.banner .page {
  background: #51d5c3;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  align-items: center;
}
.banner .page .ul {
  float: right;
  padding-top: 10px;
}
.current {
  background-color: #fff;
  border: none;
  width: 12px;
  height: 12px;
}
.font {
  text-align: left;
  color: #fff;
  float: left;
  line-height: 40px;
  padding-left: 10px;
}
.New {
  border: 1px solid #e5e5e5;
  height: 100%;
  margin-top: 10px;
  padding: 20px 10px;
}
.New h1 {
  color: #010101;
  font-weight: bold;
  padding-left: 15px;
}
.New .li {
  padding-left: 5px;
  margin: 15px 0 25px;
}
.New li {
  padding-left: 10px;
  margin: 15px 0;
  cursor: pointer;
}
.New li:hover {
  color: #51d5c3;
}
.New span {
  margin-right: 10px;
  color: #ed0000;
  font-weight: bold;
  margin-left: 10px;
}
.New h1 span {
  position: relative;
}
.New h1 span::after {
  content: "";
  width: 16px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/img/hot.png) no-repeat;
  background-size: 100%;
  position: absolute;
  right: -20px;
  top: 3px;
}
.Zhuanti {
  margin-top: 10px;
  background-color: #f2f2f2;
  padding: 10px 0;
  padding-right: 10px;
  margin-bottom: 20px;
}
.Zhuanti h1 {
  color: #fff;
  font-size: 18px;
  width: 90px;
  background-color: #51d5c3;
  line-height: 32px;
  text-align: center;
  position: relative;
  border-bottom: 2px solid #08a085;
}
.Zhuanti h1::after {
  content: "";
  width: 1px;
  height: 5px;
  display: inline-block;
  border: 5px solid transparent;
  border-top: 5px solid #08a085;
  font-size: 100% 100%;
  position: absolute;
  left: 40px;
  bottom: -15px;
}
.Zhuanti .top {
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  justify-content: space-between;
}
.Zhuanti .top p {
  font-size: 16px;
  cursor: pointer;
}
.Zhuanti .top p:hover {
  color: #51d5c3;
}
.Zhuanti img {
  width: 100px;
  margin-right: 20px;
}
.Zhuanti .list {
  padding: 15px 10px;
}
.Zhuanti .list p {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}
.Zhuanti .list li {
  border-bottom: 1px dashed #d8d8d8;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;
  min-height: 60px;
}
.position {
  position: relative;
  width: 100%;
}
</style>
