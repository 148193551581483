import { getRequest, postJsonRequest } from '@/api/axios'

// 获取热点资讯和实时资讯
export const getNews = (params) => {
  return postJsonRequest('/blade-integrate/openapi/integrateinformation/listByType', params)
}

// 农业资讯id
export const getQuotes = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}

export const getQuotesList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}

export const getdetail = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/detail', params)
}
